import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter, map } from 'rxjs';

import { BusEvent, EventBus } from 'framework';

import { EventNames } from '../../constants/event-names';

import { SolisSessionService } from '../session/solis-session.service';
import { SolisLaunchMethod } from '../session/solis-session-service.enums';

@Injectable({
  providedIn: 'root'
})
export class SiteLayoutService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private eventBus: EventBus,
    private solisSessionService: SolisSessionService
  ) {}

  initialize(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === PRIMARY_OUTLET))
      .subscribe((route) => {
        // Child layoutData overrides parents
        let currentRoute: ActivatedRoute | null | undefined = route;

        while (currentRoute) {
          const layoutData = currentRoute.snapshot.data['layout'];

          if (layoutData) {
            this.configureSite(layoutData);
            break;
          }

          currentRoute = currentRoute?.parent;
        }
      });
  }

  private configureSite(requestedRouteLayoutData: any): void {
    // Depending on the current site launch mode, some sections cannot be shown
    // no matter what
    const unavailableSections: string[] = [];

    switch (this.solisSessionService.launchMethod) {
      case SolisLaunchMethod.Embed:
        // When embedded, navbar is unavailable
        unavailableSections.push(...['navbar']);
        break;
    }

    let finalLayout: string[] = [];
    finalLayout.push(...requestedRouteLayoutData);

    // Remove unavailable sections from requested sections
    finalLayout = finalLayout.filter((l) => !unavailableSections.includes(l));

    this.eventBus.emit(new BusEvent<string[]>(EventNames.siteLayoutChanged, finalLayout));
  }
}

<!-- This section only displays if session is not initialized -->
@if (!solisSessionService.isInitialized || initError.length > 0) {
  <div class="app-body">
    <!-- Display any startup errors. If this ever shows it likely means there's a bug in system. -->
    @if (initError.length > 0) {
      <div>{{ displayInitError }}</div>
    }

    <!-- Firm selection -->
    @if (!solisSessionService.isInitialized && advisorFirmsForDirectLogin.length > 0) {
      <div>
        <div class="app-header">Login as an advisor:</div>
        <p-selectButton [options]="advisorFirmsForDirectLogin" [(ngModel)]="selectedFirm" optionLabel="icon">
          <ng-template let-item pTemplate>
            <div>{{ item.name }} ({{ item.dtccMemberCode }})</div>
          </ng-template>
        </p-selectButton>
      </div>
    }
    @if (!solisSessionService.isInitialized && partnerFirmsForDirectLogin && partnerFirmsForDirectLogin.length > 0) {
      <div class="mt-2">
        <div class="app-header">Login as a partner:</div>
        <p-selectButton [options]="partnerFirmsForDirectLogin" [(ngModel)]="selectedFirm" optionLabel="icon">
          <ng-template let-item pTemplate>
            <div>{{ item.detailName }} - {{ item.type.toString() }} ({{ item.dtccMemberCode }})</div>
          </ng-template>
        </p-selectButton>
      </div>
    }

    @if (!solisSessionService.isInitialized && (advisorFirmsForDirectLogin.length > 0 || partnerFirmsForDirectLogin.length > 0)) {
      <div class="mt-4">
        <p-button label="Go" class="mt-3" [disabled]="!selectedFirm" (onClick)="onFirmSelected()"></p-button>
      </div>
    }

    @if (errors.length > 0) {
      <div class="card">
        @for (error of errors; track error) {
          <div class="card-header">{{ error }}</div>
        }
      </div>
    }
  </div>
}

<!-- Main application content. Depending on the current route will host an Advisor or Partner page -->
@if (solisSessionService.isInitialized) {
  <p-blockUI [blocked]="blockDocument">
    <svg class="initial-loader" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="45" />
    </svg>
    @if (blockMessage !== '') {
      <div class="message">{{ blockMessage }}</div>
    }
  </p-blockUI>
  <router-outlet></router-outlet>
}

<!-- App-wide toasts used by ToastService / MessageService -->
<p-toast key="toast" position="bottom-center">
  <ng-template let-message pTemplate="message">
    <div>
      <div style="font-weight: bold" class="row">
        <p innerHtml="{{ message.summary }}"></p>
      </div>
      <div class="row">
        <p innerHtml="{{ message.detail }}"></p>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-toast key="progress" position="bottom-center">
  <ng-template let-message pTemplate="message">
    <p-progressSpinner [style]="{ width: '20px', height: '20px' }"></p-progressSpinner>
    <p class="ms-2" innerHtml="{{ message.detail }}"></p>
  </ng-template>
</p-toast>

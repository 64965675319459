import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.authority,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: 'solis_web',
        scope: 'openid profile gateway_api_all', // 'openid profile ' + your scopes
        responseType: 'code',
        silentRenew: true,
        silentRenewUrl: window.location.origin + '/static/silent-renew.html',
        renewTimeBeforeTokenExpiresInSeconds: 10
      }
    })
  ],
  exports: [AuthModule]
})
export class AuthConfigModule {}

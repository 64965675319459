import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SolisSessionService } from '../session/solis-session.service';

@Injectable()
export class ImpersonationInterceptor implements HttpInterceptor {
  constructor(private solisSessionService: SolisSessionService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newRequest = request;

    if (this.solisSessionService.isImpersonationActive) {
      newRequest = request.clone({
        setHeaders: { 'Impersonate-User': `${this.solisSessionService.user.id}` }
      });
    }

    return next.handle(newRequest);
  }
}
